<template>
    <v-card :elevation="0">
        <div class="spinner"
            v-if="page.spinner">
        </div>

        <v-tabs v-model="tab"
            @change="getSystemsForTab"
            grow>
            <v-tab>{{ $t('dashboard.active') }} ({{ systemsActiveCounts }})</v-tab>
            <v-tab>{{ $t('testing') }} ({{ systemsTestingCounts }})</v-tab>
            <v-tab>{{ $t('paused') }} ({{ systemsPausedCounts }})</v-tab>
            <v-tab>{{ $t('dashboard.inactive') }} ({{ systemsInactiveCounts }})</v-tab>
            <v-tab>{{ $t('All') }} ({{ allSystemLength }})</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <div id="tiles-container">
                    <v-container fluid grid-list-md class="pa-0">

                    <v-expansion-panels v-if="loaded"
                        v-model="activePanels"
                        accordion
                        flat
                        :elevation="0"
                        multiple>
                        <v-expansion-panel
                            class="expanel"
                            v-for="(item,i) in expandables"
                            :key="i">

                                <v-expansion-panel-header>
                                    <v-row justify-start>
                                        <v-icon medium
                                            :color="item.color">
                                            {{ item.icon }}
                                        </v-icon>
                                        <label class="theme--light ml-2 title">{{ item.title }}{{ systemCounts[item.type] > 0 ? ` (${systemCounts[item.type]})` : '' }}</label>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content
                                    class="px-md-0">
                                    <v-data-iterator
                                        :ref="`iterator-${item.type}`"
                                        :id="`iterator-${item.type}`"
                                        :items="!loaded ? Array.from(Array(10).keys()) : systems[item.type]"
                                        :options.sync="table.pagination"
                                        row
                                        hide-default-footer
                                        no-data-text=""
                                        :no-results-text="`${$t('no_matching')} - ${table.searchText} -`"
                                        :search="searchText"
                                        wrap>
                                        <template v-slot:item="props">

                                            <div class="tile-container">

                                                <v-skeleton-loader v-if="!loaded"
                                                    class="mx-auto"
                                                    width="210"
                                                    type="card"
                                                ></v-skeleton-loader>

                                                <SystemTile v-else
                                                    :id="`systemTile_${props.item.id}`"
                                                    :ref="`systemTile_${props.item.id}`"
                                                    :type="props.item.enabled"
                                                    :imageWidth="tileWidth"
                                                    :cameraNumber="parseInt(props.item.camNo)"
                                                    :computerName="props.item.computerName"
                                                    :projectName="props.item.projectName"
                                                    :systemId="props.item.id"
                                                    :client="props.item.clientName"
                                                    :timezone="props.item.timezone"
                                                    :healthData.sync="props.item.health" />
                                            </div>

                                        </template>
                                    </v-data-iterator>
                                </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                    </v-container>
                </div>
            </v-tab-item>

            <v-tab-item v-for="(selection, idx) in [systemsTesting, systemsPaused, systemsInactive, allSystems]"
                :key="idx">
                <div id="tiles-container">
                    <v-container fluid grid-list-md class="pa-0">

                        <v-data-iterator
                            :items="selection"
                            :options.sync="table.pagination"
                            row
                            hide-default-footer
                            no-data-text=""
                            :no-results-text="`No matching records found for - ${table.searchText} -`"
                            :search="table.searchText"
                            wrap>
                            <template v-slot:item="props">

                                <div class="tile-container">

                                    <SystemTile
                                        :id="`systemTile_${props.item.id}`"
                                        :ref="`systemTile_${props.item.id}`"
                                        :type="props.item.enabled"
                                        :imageWidth="tileWidth"
                                        :cameraNumber="parseInt(props.item.camNo)"
                                        :computerName="props.item.computerName"
                                        :projectName="props.item.projectName"
                                        :systemId="props.item.id"
                                        :client="props.item.clientName"
                                        :timezone="props.item.timezone"
                                        :showImage="[0, 1, 2].indexOf(tabId) >= 0" />
                                </div>

                            </template>
                        </v-data-iterator>
                    </v-container>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { rest } from '../_helpers'
import SystemTile from '@/components/SystemTile.vue'

const cron = require('node-cron')

const misc = require('../_helpers/misc')

export default {
    data() {
        return {
            tab: null,
            tabId: 0,
            systemsTesting: [],
            systemsPaused: [],
            systemsInactive: [],
            systemsActiveCounts: 0,
            systemsTestingCounts: 0,
            systemsPausedCounts: 0,
            systemsInactiveCounts: 0,
            systemIdList: [],
            loaded: false,
            updateCron: null,
            updateCronStarted: false,
            fetchingSystems: false,
            fetchStatus: [],
            systems: {
                ok: [],
                warning: [],
                error: [],
            },
            activePanels: [],
            systemCounts: { ok: 0, warning: 0, error: 0 },
            systemsOrder: [
                {
                    type: 'error',
                    title: this.$t('error'),
                    icon: 'mdi-alert-octagon',
                    color: 'error',
                },
                {
                    type: 'warning',
                    title: this.$t('warning'),
                    icon: 'mdi-alert',
                    color: 'warning',
                },
                {
                    type: 'ok',
                    title: this.$t('ok'),
                    icon: 'mdi-check-bold',
                    color: 'success',
                },
            ],
            tileWidth: 13,

            gaugeAll: {
                text: this.$t('cameras'),
                icon: 'mdi-camera-iris',
                count: 0,
                visible: true,
                items: [],
            },
        }
    },

    computed: {
        ...mapState({
            page: 'page',
            table: 'table',
        }),

        expandables() {
            const expandables = []
            this.systemsOrder.forEach(item => {
                if (this.systemCounts[item.type] >= 0 || !this.loaded) {
                    expandables.push(item)
                }
            })

            return expandables
        },

        searchText() {
            this.setSystemCounts()
            return this.table.searchText
        },

        allSystemLength() {
            return this.systemsActiveCounts + this.systemsTestingCounts + this.systemsPausedCounts + this.systemsInactiveCounts
        },

        allSystems() {
            return [this.systems.ok, this.systems.warning, this.systems.error, this.systemsTesting, this.systemsPaused, this.systemsInactive].flat(1)
        },
    },

    components: {
        SystemTile,
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setSortItems: 'table/setSortItems',
            setSorting: 'table/setSorting',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
            setGauges: 'page/setGauges',
        }),

        getSystemsForTab(tabId) {
            this.tabId = tabId
            this.getSystems(true)
        },

        setSystemCounts() {
            setTimeout(() => {
                ['error', 'warning', 'ok'].forEach(sType => {
                    const iterator = this.$refs[`iterator-${sType}`]
                    if (iterator && iterator.length) {
                        this.systemCounts[sType] = iterator[0].$el.getElementsByClassName('tile-container').length
                    }
                })
            }, 500)
        },

        refresh() {
            if (this.loaded && !this.fetchingSystems) {
                this.getSystemCounts()
                this.getSystems(true)
            }
        },

        getSystemCounts() {
            const payload = {
                attributes: ['enabled'],
                includes: [],
            }

            return rest.getRows('system', payload)
                .then(systems => {
                    let activeCounts = 0
                    let testingCounts = 0
                    let pausedCounts = 0
                    let inactiveCounts = 0

                    systems.forEach(system => {
                        if (system.enabled === 1) {
                            activeCounts += 1
                        } else if (system.enabled === 0) {
                            inactiveCounts += 1
                        } else if (system.enabled === 2) {
                            testingCounts += 1
                        } else if (system.enabled === 3) {
                            pausedCounts += 1
                        }
                    })

                    this.systemsActiveCounts = activeCounts
                    this.systemsTestingCounts = testingCounts
                    this.systemsPausedCounts = pausedCounts
                    this.systemsInactiveCounts = inactiveCounts
                })
        },

        getSystems(refreshOnly) {
            const currentId = this.tabId
            if (this.fetchStatus.indexOf(currentId) < 0) {
                this.fetchStatus.push(currentId)
                if ((this.tabId === 0 && this.systemsActiveCounts <= 0)
                    || (this.tabId === 1 && this.systemsTesting.length <= 0)
                    || (this.tabId === 2 && this.systemsPaused.length <= 0)
                    || (this.tabId === 3 && this.systemsInactive.length <= 0)
                    || (this.tabId === 4 && this.allSystemLength <= 0)) {
                    this.showSpinner(true)
                }

                this.fetchingSystems = true

                const payload = {
                    attributes: [
                        'id',
                        'camNo',
                        ['computerData.computer.name', 'computerName'],
                        ['project.name', 'projectName'],
                        ['project.location', 'projectLocation'],
                        ['project.client.name', 'clientName'],
                        ['config.timezone', 'timezone'],
                        ['project.country', 'country'],
                        ['project.city', 'city'],
                        ['routerData.make', 'routerMake'],
                        ['config.shutterInterval', 'shutterInterval'],
                        ['housingData.make', 'housingMake'],
                        'enabled',
                        'disabledAt',
                        'createdAt',
                        'voltage',
                        'pump',
                        'router',
                        'version',
                    ],
                    includes: [
                        {
                            type: 'project',
                            attributes: ['id'],
                        },
                        {
                            type: 'computerData',
                            attributes: [],
                        },
                        {
                            type: 'routerData',
                            attributes: [],
                        },
                        {
                            type: 'errorData',
                        },
                        {
                            type: 'config',
                            attributes: ['timezone'],
                        },
                        {
                            type: 'housingData',
                            attributes: [],
                        },
                    ],
                }

                if (this.tabId === 0) {
                    payload.where = { enabled: 1 }
                } else if (this.tabId === 1) {
                    payload.where = { enabled: 2 }
                } else if (this.tabId === 2) {
                    payload.where = { enabled: 3 }
                } else if (this.tabId === 3) {
                    payload.where = { enabled: 0 }
                } else if (this.tabId === 4) {
                    delete payload.where
                }

                if (!refreshOnly) {
                    this.systemCounts = { ok: -1, warning: -1, error: -1 }
                }

                const newSystems = {
                    ok: [],
                    warning: [],
                    error: [],
                }

                this.systemIdList = []
                this.systemsTesting = []
                this.systemsPaused = []
                this.systemsInactive = []

                return rest.getRows(`system${this.tabId === 3 ? '/nostats' : ''}`, payload)
                    .then(systems => {
                        const counts = {
                            ok: 0,
                            warning: 0,
                            error: 0,
                            inactive: 0,
                        }

                        systems.forEach(system => {
                            if (system.enabled === 1) {
                                const { health, level } = misc.healthCheck(system)
                                system.health = health

                                if (this.$refs[`systemTile_${system.id}`] && this.$refs[`systemTile_${system.id}`].length) {
                                    this.$refs[`systemTile_${system.id}`][0].setHealth(health)

                                    const existsError = this.systems.error.findIndex(v => v.id === system.id)
                                    const existsWarning = this.systems.warning.findIndex(v => v.id === system.id)
                                    const existsOk = this.systems.ok.findIndex(v => v.id === system.id)

                                    if (level >= 2) {
                                        if (existsError < 0) {
                                            this.systems.error.push(system)
                                            this.systemCounts.error += 1
                                            if (existsWarning >= 0) {
                                                this.systems.warning.splice(existsWarning, 1)
                                                this.systemCounts.warning -= 1
                                            } else if (existsOk >= 0) {
                                                this.systems.ok.splice(existsOk, 1)
                                                this.systemCounts.ok -= 1
                                            }
                                        }
                                    } else if (Object.keys(health).length) {
                                        if (existsWarning < 0) {
                                            this.systems.warning.push(system)
                                            this.systemCounts.warning += 1
                                            if (existsError >= 0) {
                                                this.systems.error.splice(existsError, 1)
                                                this.systemCounts.error -= 1
                                            } else if (existsOk >= 0) {
                                                this.systems.ok.splice(existsOk, 1)
                                                this.systemCounts.ok -= 1
                                            }
                                        }
                                    } else {
                                        if (existsOk < 0) {
                                            this.systems.ok.push(system)
                                            this.systemCounts.ok += 1
                                            if (existsWarning >= 0) {
                                                this.systems.warning.splice(existsWarning, 1)
                                                this.systemCounts.warning -= 1
                                            } else if (existsError >= 0) {
                                                this.systems.error.splice(existsError, 1)
                                                this.systemCounts.error -= 1
                                            }
                                        }
                                    }
                                } else {
                                    if (level >= 2) {
                                        newSystems.error.push(system)
                                        counts.error += 1
                                    } else if (Object.keys(health).length) {
                                        newSystems.warning.push(system)
                                        counts.warning += 1
                                    } else {
                                        newSystems.ok.push(system)
                                        counts.ok += 1
                                    }
                                }
                            } else if (system.enabled === 0) {
                                // newSystems.inactive.push(system)
                                if (this.systemIdList.indexOf(system.id) < 0) {
                                    this.systemsInactive.push(system)
                                }
                                counts.inactive += 1
                            } else if (system.enabled === 2) {
                                // newSystems.testing.push(system)
                                if (this.systemIdList.indexOf(system.id) < 0) {
                                    this.systemsTesting.push(system)
                                }
                                counts.testing += 1
                            } else if (system.enabled === 3) {
                                // newSystems.paused.push(system)
                                if (this.systemIdList.indexOf(system.id) < 0) {
                                    this.systemsPaused.push(system)
                                }
                                counts.paused += 1
                            }

                            this.systemIdList.push(system.id)
                        })

                        if (!refreshOnly) {
                            this.systemCounts = counts
                            this.systems = newSystems

                            let count = 0
                            if (this.systemCounts.error >= 1) {
                                this.activePanels.push(count)
                            }
                            count += 1
                            if (this.systemCounts.warning >= 1) {
                                this.activePanels.push(count)
                            }
                            count += 1
                            if (this.systemCounts.ok >= 1) {
                                this.activePanels.push(count)
                            }
                        }

                        this.$set(this.systemCounts, 'ok', this.systems.ok.length)
                        this.$set(this.systemCounts, 'warning', this.systems.warning.length)
                        this.$set(this.systemCounts, 'error', this.systems.error.length)

                        if (this.systemCounts.error === 0 && this.activePanels.indexOf(0) >= 0) {
                            this.activePanels.splice(this.activePanels.indexOf(0), 1)
                        }
                        if (this.systemCounts.warning === 0 && this.activePanels.indexOf(1) >= 0) {
                            this.activePanels.splice(this.activePanels.indexOf(1), 1)
                        }
                        if (this.systemCounts.ok === 0 && this.activePanels.indexOf(2) >= 0) {
                            this.activePanels.splice(this.activePanels.indexOf(2), 1)
                        }

                        if (!this.updateCronStarted) {
                            this.updateCronStarted = true
                            this.updateCron.start()
                        }

                        const barGauges = []
                        if (this.systemCounts.ok) {
                            barGauges.push({
                                text: '',
                                color: 'success',
                                icon: 'mdi-check-bold',
                                count: this.systemCounts.ok,
                                visible: true,
                                click: () => {
                                    this.activePanels = [2]
                                    // setSorting([[item.click]])
                                },
                            })
                        }
                        if (this.systemCounts.warning) {
                            barGauges.push({
                                text: '',
                                color: 'warning',
                                icon: 'mdi-alert',
                                count: this.systemCounts.warning,
                                visible: true,
                                click: () => {
                                    this.activePanels = [1]
                                },
                            })
                        }
                        if (this.systemCounts.error) {
                            barGauges.push({
                                text: '',
                                color: 'error',
                                icon: 'mdi-alert-octagon',
                                count: this.systemCounts.error,
                                visible: true,
                                click: () => {
                                    this.activePanels = [0]
                                },
                            })
                        }

                        if (barGauges.length) {
                            this.gaugeAll.items = barGauges
                        }

                        if (this.gaugeAll.count !== systems.length) {
                            this.gaugeAll.count = this.systemCounts.ok + this.systemCounts.warning + this.systemCounts.error
                            this.setGauges([this.gaugeAll])
                        }

                        return true
                    })
                    .catch(err => {
                        // this.updateCron.stop()
                        console.log('system update', err)

                        return false
                    })
                    .finally(() => {
                        this.showSpinner(false)

                        this.setSystemCounts()

                        this.fetchingSystems = false
                        this.loaded = true

                        const index = this.fetchStatus.indexOf(currentId)
                        if (index > -1) {
                            this.fetchStatus.splice(index, 1)
                        }
                    })
            }

            return true
        },
    },

    mounted() {
        const second = Math.floor(Math.random() * 60)
        const cronTime = `${second} */5 * * * *`
        this.updateCron = cron.schedule(cronTime, () => {
            this.refresh()
        })
    },

    created() {
        this.setNew({
            type: 'system',
            title: 'System',
            modified: (idx, item) => {
                this.refresh()
                this.resetNew()
            },
        })

        this.setSorting([['project.client.name'], ['project.name'], ['camNo']])

        this.setSortItems([
            {
                sorting: [['project.client.name'], ['project.name'], ['camNo']],
                name: 'Project',
                icon: 'mdi-folder-outline',
            },
            {
                sorting: [['computerName']],
                name: 'Computer',
                icon: 'mdi-laptop',
            },
            {
                sorting: [['createdAt', true]],
                name: 'Creation Date',
                icon: 'mdi-calendar-star',
            },
            {
                sorting: [['disabledAt', false]],
                name: 'Finishing Date',
                icon: 'mdi-calendar-remove',
            },
        ])

        this.showSpinner(true)

        this.getSystemCounts()
        this.getSystems(false)

        // var notification = new Notification('Hallo', { body: 'What you want to do?!' });
        // ServiceWorkerRegistration.showNotification('Hallo', {body: 'What you want to do?!' });
    },

    beforeDestroy() {
        this.updateCron.stop()
    },
}
</script>


<style lang="stylus" scoped>
    .tile-container
        margin 1em
        float left

    #tiles-container
        display inline-flex
        width 100%

    .expanel
        border-bottom 1px solid #eee

    @media (max-width: 959px)
        .v-expansion-panel-content>>> .v-expansion-panel-content__wrap
            padding 0 !important
</style>
